import * as React from 'react';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog, Transition } from '@headlessui/react';
import { SupportWidgetContext } from './SupportWidget';
import { createContactRequest } from '../../../Utilities/api';
import pThrottle from 'p-throttle';
import { toast } from 'react-hot-toast';
const throttle = pThrottle({
    limit: 1,
    interval: 200,
});
const initialErrorFields = {
    name: '',
    reason: '',
    subject: '',
    message: '',
    email: '',
    consent: '',
};
const SupportPanel = () => {
    const { authenticated, reasons, isOpen, handleOpen } = React.useContext(SupportWidgetContext);
    const [isFetching, setIsFetching] = useState(false);
    const [errors, setErrors] = useState(initialErrorFields);
    const { register, handleSubmit, reset } = useForm();
    const onSubmit = data => {
        submitForm(data);
    };
    const submitForm = throttle(async (data) => {
        setIsFetching(true);
        createContactRequest(data).then((json) => {
            setIsFetching(false);
            if (json.success) {
                handleOpen();
                reset();
                setErrors(initialErrorFields);
                toast.success('Vielen Dank für deine Kontaktanfrage!');
            }
            else {
                if (json.errors) {
                    setErrors(json.errors);
                    toast.error('Bitte überprüfe das Formular auf Fehler.');
                }
                else {
                    toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.');
                }
            }
        });
    });
    return <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleOpen}>
            <div className="fixed inset-0"/>

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                        <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500 sm:duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div className="bg-brand-primary px-4 py-6 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                                                Kontakt
                                            </Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button type="button" className="text-white hover:text-gray-200 text-xl" onClick={handleOpen}>
                                                    <span className="sr-only">Close panel</span>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-1">
                                            <p className="text-base text-white">
                                                Benötigst du Unterstützung bei technischen Fragen rund um Sitzkrieger? Du möchtest Lob, Kritik oder Anregungen loswerden? Unser Support-Team freut sich auf deine Nachricht!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-col justify-between">
                                        <form onSubmit={handleSubmit(onSubmit)} className="divide-y divide-gray-200 px-4 sm:px-6">
                                            <input type="email" className="hidden" tabIndex={-1} autoComplete="false" {...register('email_h')}/>
                                            <div className="space-y-6 pb-5 pt-6">
                                                <div>
                                                    <label htmlFor="reason" className="block text-base text-gray-900 after:content-['_*'] after:text-red-600">
                                                        Kontaktgrund
                                                    </label>
                                                    <div className="mt-2">
                                                        <select id="reason" className="bg-white block w-full rounded-md border border-gray-400 p-1.5 text-md text-gray-900 shadow-sm" {...register('reason')}>
                                                            <option value={''}>Bitte auswählen</option>
                                                            {reasons.length && reasons.map((reason) => <option key={reason.value} value={reason.value}>{reason.key}</option>)}
                                                        </select>
                                                    </div>
                                                    {errors.reason
            ? <span className="mt-2 text-sm text-red-600 mt-2">{errors.reason}</span>
            : <></>}
                                                </div>

                                                {!authenticated
            ? <>
                                                        <div>
                                                            <label htmlFor="email" className="block text-base text-gray-900 after:content-['_*'] after:text-red-600">
                                                                E-Mail
                                                            </label>
                                                            <div className="mt-2">
                                                                <input type="email" id="email" className="block w-full rounded-md border border-gray-400 p-1.5 text-base text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2" {...register('email')}/>
                                                            </div>
                                                            {errors.email
                    ? <span className="mt-2 text-sm text-red-600 mt-2">{errors.email}</span>
                    : <></>}
                                                        </div>

                                                        <div>
                                                            <label htmlFor="name" className="block text-base text-gray-900 after:content-['_*'] after:text-red-600">
                                                                Name
                                                            </label>
                                                            <div className="mt-2">
                                                                <input type="text" id="name" className="block w-full rounded-md border border-gray-400 p-1.5 text-base text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2" {...register('name')}/>
                                                            </div>
                                                            {errors.name
                    ? <span className="mt-2 text-sm text-red-600 mt-2">{errors.name}</span>
                    : <></>}
                                                        </div>
                                                    </>
            : <></>}
                                                <div>
                                                    <label htmlFor="subject" className="block text-base text-gray-900 after:content-['_*'] after:text-red-600">
                                                        Betreff
                                                    </label>
                                                    <div className="mt-2">
                                                        <input type="text" id="subject" className="block w-full rounded-md border border-gray-400 p-1.5 text-base text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2" {...register('subject')}/>
                                                    </div>
                                                    {errors.subject
            ? <span className="mt-2 text-sm text-red-600 mt-2">{errors.subject}</span>
            : <></>}
                                                </div>

                                                <div>
                                                    <label htmlFor="message" className="block text-base text-gray-900 after:content-['_*'] after:text-red-600">
                                                        Beschreibung
                                                    </label>
                                                    <div className="mt-2">
                                                        <textarea id="message" rows={5} className="block w-full rounded-md border border-gray-400 p-1.5 text-md text-gray-900 shadow-sm placeholder:text-gray-400" defaultValue={''} {...register('message')}/>
                                                    </div>
                                                    {errors.message
            ? <span className="mt-2 text-sm text-red-600">{errors.message}</span>
            : <p className="mt-2 text-sm text-gray-600">Schildere uns dein Anliegen in ein paar Sätzen.</p>}
                                                </div>

                                                {!authenticated
            ? <div>
                                                        <div className="relative flex items-start">
                                                            <div className="flex h-6 items-center">
                                                                <input id="consent" aria-describedby="consent-description" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-brand-primary" {...register('consent')}/>
                                                            </div>
                                                            <div className="ml-3 text-sm leading-6">
                                                                <label htmlFor="consent" id="consent-description" className="text-gray-500 after:content-['_*'] after:text-red-600">
                                                                    Ich habe die Hinweise zum <a className="text-brand-primary" href="/datenschutz">Datenschutz</a> gelesen und willige in die Erhebung, Verarbeitung und Speicherung meiner im Formular angegebenen Daten wie in der <a className="text-brand-primary" href="/datenschutz">Datenschutzerklärung</a> beschrieben ein.
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {errors.consent
                    ? <span className="mt-2 text-sm text-red-600 mt-2">{errors.consent}</span>
                    : <></>}
                                                    </div>
            : <></>}
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <button type="button" className="rounded-md bg-white border-0 px-3 py-2 text-base text-gray-900" onClick={handleOpen}>
                                                    Abbrechen
                                                </button>
                                                {isFetching
            ? <div className="px-3 py-2 text-base">
                                                        <svg aria-hidden="true" role="status" className="w-5 h-5 text-gray-900 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                                        </svg>
                                                    </div>
            : <button type="submit" className="ml-4 inline-flex justify-center rounded-md bg-brand-secondary px-3 py-2 text-base font-semibold text-white shadow-sm hover:opacity-80">
                                                    Abschicken
                                                    </button>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Dialog>
    </Transition.Root>;
};
SupportPanel.displayName = 'SupportPanel';
export default SupportPanel;
