import * as React from 'react';
import { useState } from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import CourseModuleList from './CourseModuleList';
import { useI18n } from '../../../Translation';
import Spinner from '../Spinner';
import parse from 'html-react-parser';
import { useCourse } from './Hooks/useCourse';
import { useCourseProgress } from './Hooks/useCourseProgess';
import Confetti from 'react-confetti';
import { useInterval } from '../../../Utilities/customHooks';
const Course = (props) => {
    const { isLoading, data: course } = useCourse(props.courseUuid);
    const { isLoading: isCourseProgressLoading, data: courseProgress } = useCourseProgress(props.courseUuid);
    const [showConfetti, setShowConfetti] = useState(true);
    const i18n = useI18n();
    useInterval(() => {
        if (!isCourseProgressLoading && courseProgress && (courseProgress.progress.total === courseProgress.progress.finished)) {
            setShowConfetti(false);
        }
    }, 10 * 1000);
    if (isLoading) {
        return <Spinner />;
    }
    if (!course) {
        return <div>{i18n['course.courseNotFound']}</div>;
    }
    return <>
        {props.showTextBlocks && (<>
                <h1 className="mb-2 text-3xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight">
                    {course.title}
                </h1>
                <p className="mb-4 text-lg leading-8 text-gray-600">
                    {course.subtitle}
                </p>
            </>)}

        {!isCourseProgressLoading && courseProgress && (courseProgress.progress.total === courseProgress.progress.finished) ? <>
            <p className="mb-4 text-lg leading-8 text-gray-600">
                {showConfetti ? <Confetti width={window.innerWidth} height={window.innerHeight} gravity={0.05} run={showConfetti}/> : null}
                Du hast den Kurs erfolgreich abgeschlossen.<br />
                Dir wurde die Teilnahmebescheinigung per Mail geschickt.
            </p>
        </> : null}


        {props.showTextBlocks && course.preLecturesContent && (<section className="mb-4">
                {parse(course.preLecturesContent)}
            </section>)}

        <section className="mb-4">
            <CourseModuleList courseUuid={props.courseUuid} courseOverviewLink={course.pageLink}/>
        </section>


        {props.showTextBlocks && course.postLecturesContent && (<section className="mb-4">
                {parse(course.postLecturesContent)}
            </section>)}
    </>;
};
Course.displayName = 'Course';
export default Course;
class CourseElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = Course;
    }
}
export const loadCourseElement = () => {
    customElements.define('course-element', CourseElement);
};
