import * as React from 'react';
import CourseModuleItem from './CourseModuleItem';
import Spinner from '../Spinner';
import { useCourseModules } from './Hooks/useCourseModules';
const CourseModuleList = (props) => {
    const { isLoading, data: courseModules } = useCourseModules(props.courseUuid);
    if (isLoading) {
        return <Spinner />;
    }
    return <div className="h-full overflow-y-auto grid grid-cols-1 gap-6">
        {courseModules && courseModules.map((courseModule, index) => (<CourseModuleItem key={courseModule.uuid} courseOverviewLink={props.courseOverviewLink} courseUuid={props.courseUuid} courseModule={courseModule} defaultOpen={index <= 0}/>))}
    </div>;
};
CourseModuleList.displayName = 'CourseModuleList';
export default CourseModuleList;
