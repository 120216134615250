import * as React from 'react';
import BaseElement from '../../CustomElements/BaseElement';
import { useI18n } from '../../../Translation';
const CourseOverview = ({ courses }) => {
    const i18n = useI18n();
    return <>
        <div className="pb-5">
            <h3 className="mt-5 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                {i18n['courses.activatedCoursesHeading']}
            </h3>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
            {courses.map((course, i) => {
            const activatedDate = course.myCourse.activatedAt ? new Date(course.myCourse.activatedAt) : null;
            if (!course.link) {
                return <></>;
            }
            return <div key={'course-' + i} className="overflow-hidden relative rounded-lg bg-white shadow-lg">
                    <>
                        <div className="relative">
                            <div className="relative h-72 w-full overflow-hidden rounded-lg rounded-b-none">
                                <img src="/static/Logo_Head.png" alt="" className="h-full w-full object-cover object-right md:object-center"/>
                            </div>

                            <div className="absolute inset-x-0 top-0 h-72 overflow-hidden flex flex-col justify-end rounded-lg rounded-b-none p-4 sm:px-6">
                                <div aria-hidden="true" className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-75"></div>
                                <div className="relative flex justify-between items-center">
                                    <span className="text-xl font-semibold tracking-tight text-white">{course.course.title}</span>
                                </div>
                                <div className="relative mt-4">
                                    <div className="flex flex-col justify-center">
                                        {course.myCourse.activatedAt ? <>
                                            <ul>
                                                <li className="text-white">{i18n['course.mycourse.activatedSince']}: {activatedDate?.toLocaleDateString('de', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })}</li>
                                            </ul>
                                        </> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative inset-x-0 bottom-0 bg-gray-50 p-4 sm:px-6">
                            <div className="text-sm flex justify-between items-center">
                                <a href={course.link} className="text-sm font-semibold leading-6 hover:text-[#00537e] rounded hover:bg-[#00537e]/[.08] px-2 py-1 shadow-sm text-white bg-[#00537e]">
                                    {i18n['course.goToCourse']}
                                </a>
                            </div>
                        </div>
                    </>
                </div>;
        })}
        </div>
    </>;
};
CourseOverview.displayName = 'CourseOverview';
export default CourseOverview;
class CourseOverviewElement extends BaseElement {
    constructor() {
        super(...arguments);
        this.element = CourseOverview;
    }
}
export const loadCourseOverviewElement = () => {
    customElements.define('course-overview', CourseOverviewElement);
};
